import { useState } from "react";
// import emailjs from "emailjs-com";
import mapicon from '../../assets/img/location.png';
import phoneicon from '../../assets/img/phone.png';
import mailicon from '../../assets/img/mail.png';
import React from "react";
import './footer.styles.scss';

const initialState = {
  name: "",
  email: "",
  message: "",
};





const Footer = (props) => {


    const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  const handleSubmit = (e) => {
    console.log(name);
    console.log(email);
    console.log(message);
    clearState();
    // e.preventDefault();
    // console.log(name, email, message);
    
    // {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
    
    // emailjs
    //   .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       clearState();
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
  };








    return (
    <div>
      <div id="contact">
        <div >
        <div  className="footer-container">
          <div className="colmd8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="firstRow">
                  <div className="colmd6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="colmd7">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="colmd3 ">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>

                  <img src={mapicon} alt="mapicon" className='footer-icon'/> Address
                </span>
                271, rue abou zaid dadoussi, maarif, casablanca
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                <img src={phoneicon} alt="mapicon" className='footer-icon'/> Phone
                </span>{" "}
                +212 661 07 25 12 
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span  style={{display: "flex" }}>
                <img src={mailicon} alt="mailicon" className='footer-icon'/>  Email
                </span>
                
                atlagh.amine@gmail.com
              </p>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container-center-text">
          <p>
            &copy; 2024 Ravostrat. 
          </p>
        </div>
      </div>
        </div>
    )
}

export default Footer;