import { useState } from 'react';

import FormInput from '../../components/form-input/form-input.component';
import Button from '../../components/button/button.component';

import './addtruck.style.scss';


const defaultFormFields = {
    marque: '',
    type: '',
    poidsMax: '',
    poidsMin: '',
    point: '',
    heure: '',
    depot: '',
    nom: '',
    prenom: '',
    cin: ''

}




const Addtruck = () => {

    const [formFields, setFormFields] = useState(defaultFormFields);
    const {marque, type, poidsMax, poidsMin, point, heure, depot, nom, prenom, cin} = formFields;



    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }
    
    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormFields({...formFields,  [name]: value});

    }

    const handleSubmit = () => {
        console.log("test")
        resetFormFields();
    }



   return ( 
    <div className='add-truck-container'>
    <h1 >Please enter the information bellow in order to add your truck</h1>
    <span></span>
    <form onSubmit={handleSubmit}  >

        <FormInput label="Marque du Transport*" type="text" required onChange={handleChange} name="marque" value={marque} />


        <FormInput label="Type de Transport* (: Frigo, Plateau, Camion Fourgon, Sec, Camion Port Char, Camion Port Voiture, Camion Van, ou autre)" type="text" required onChange={handleChange} name="type" value={type} />


        <FormInput label="Poids Maximum*" type="text" required onChange={handleChange} name="poidsMax" value={poidsMax} />

        <FormInput label="Poids Minimum" type="text"  onChange={handleChange} name="poidsMin" value={poidsMin} />

        <FormInput label="Point de Charge*" type="text" required onChange={handleChange} name="point" value={point} />

        <FormInput label="Heure de Depart*" type="text" required onChange={handleChange} name="heure" value={heure} />

        <FormInput label="Depot a*" type="text" required onChange={handleChange} name="depot" value={depot} />

        <FormInput label="Nom du Conducteur*" type="text" required onChange={handleChange} name="nom" value={nom} />
        <FormInput label="Prenom du Conducteur*" type="text" required onChange={handleChange} name="prenom" value={prenom} />
        <FormInput label="CIN du Conducteur*" type="text" required onChange={handleChange} name="cin" value={cin} />


        <Button type="submit" >Sign Up</Button>

    </form>
</div>


   )

}

export default Addtruck;