import React, { useState, useEffect, useRef} from 'react';
import CardItems from '../../components/cards/cards.component';
import filtericon from '../../assets/img/sorting.png';
import reorder from '../../assets/img/arrow-down.png';
import './cardlist.styles.scss'



const Cardlist =({arr})=>{

const [sortOption, setSortOption] = useState("Price (Lowest to Highest)");
const [sortOptionStatus, setSortOptionStatus] = useState(false);

let menuRef = useRef();

useEffect(() => {
    let handler = (e) => {
        if(!menuRef.current.contains(e.target)){
            setSortOptionStatus(false);
        }

        
        
    }
 
    document.addEventListener("mousedown", handler)


    return () => document.removeEventListener("mousedown", handler);
}, [sortOptionStatus])

return(
<div>
    <h1>Trucks Found: {arr.length}</h1>
   <div  ref={menuRef} className='sorting-wrap'  >
        <div  className= "sortTrucks noselect" onClick={() => {setSortOptionStatus(!sortOptionStatus)}}>
            <img src={filtericon} alt="filtericon" className='filter-icon'/>
            <h2 > Sort By: {sortOption}</h2>
            <img src={reorder} alt="filtericon" className='arrow-icon'/>
        </div>
        <div  className={`sorting-options ${sortOptionStatus? 'visible' : 'hidden'}`}>
                <p onClick={() => {setSortOption('Price (Lowest to Highest)');  setSortOptionStatus(!sortOptionStatus)}} style= {{fontSize: "20px", margin: "30px", cursor: "pointer"}}>Price (Lowest to Highest)</p>
                <p onClick={() => {setSortOption('Price (Highest to Lowest)');  setSortOptionStatus(!sortOptionStatus)}} style= {{fontSize: "20px", margin: "30px", cursor: "pointer"}}>Price (Highest to Lowest)</p>
                <p onClick={() => {setSortOption('Newest Posted');  setSortOptionStatus(!sortOptionStatus)}} style= {{fontSize: "20px", margin: "30px", cursor: "pointer"}}>Newest Posted</p>
                <p onClick={() => {setSortOption('Oldest Posted');  setSortOptionStatus(!sortOptionStatus)}} style= {{fontSize: "20px", margin: "30px", cursor: "pointer"}}>Oldest Posted</p>
        </div>
    </div>
    <div>
        {
        arr.map((test) => <CardItems key={test.key} title={test.title} type={test.type} location={test.location} price={test.price}/> 
        
        ) 
        
        }
        
    </div>
</div>

)




};






export default Cardlist;
