import React from 'react';
import Navigation from './components/navigation/navigation.component';
import CardItems from './components/cards/cards.component';
import './App.css';
import {Routes, Route} from 'react-router-dom';
import Demo from './sections/demo/demo.section.jsx';
import Home from './pages/home/home';
import SignUpForm from './components/sign-up/sign-up.component';
import SignInForm from './components/sign-in/sign-in.component';
import Findtruck from './pages/findtruck/findtruck.pages.jsx';
import Addtruck from './pages/addtruck/addtruck.page.jsx';


const App = () => {
  return(
    <Routes>
    <Route path='/' element={<Navigation />}>
      <Route index element={<Home />}/>
      <Route index element={<CardItems />}/>
      <Route path='/sign-up' element={<SignUpForm />}/>
      <Route path='/sign-in' element={<SignInForm />}/>
      <Route path='/demo' element={<Demo />}/>
      <Route path='/findtruck' element={<Findtruck />}/>
      <Route path='/addtruck' element={<Addtruck />}/>

    </Route>
    </Routes>
//  
  );
}

export default App;