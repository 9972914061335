import Headline from "../../sections/headline/headline.section";
import Footer from "../../sections/footer/footer.section";
import Services from "../../sections/services/services.section";

import './home.scss'

const Home = () => {
    return(
        <div className="home-container">
            <Headline />
            <div className="section2">
                <div className="headline-section2">
                    <div className="fadeIn"  >image</div>
                </div>
                <div className="paragraph-section2">
                    <h2 className="par-header">Join the companies that strive to reduce their costs</h2>
                    <p className="paragraph1">Id tempor sunt veniam in elit culpa est aute. Labore Lorem tempor non ex ad dolore enim. Veniam esse laboris cillum pariatur laboris culpa cillum aute est esse commodo adipisicing laboris. Duis amet aute consequat dolor pariatur. Aliqua eiusmod exercitation qui cupidatat aliqua. Ut cupidatat excepteur qui nostrud est ut.</p>
                    <button className="sign" style={{marginTop: "0px"}}>Join Us → </button>
                </div>
            </div>
            <div className="features-section"> 
                <div className="features-header"> 
                         <h1>Features</h1>
                         <div className="illust-per" id="1"></div>
                         </div>
            
                    <div className="features-body">
                        <div className="features-Per">
                        {/* <div className="illust-per" id="1"></div> */}
                            <h2 className="Per-header">High Speed Performance</h2>
                            <p className="p-per">I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or
                                 double click me to add your own content and make changes to the font.</p>
                        </div>
                        <div className="features-Sup">
                            <h2 className="sup-header">24/7 Online Support</h2>
                            <p className="p-sup"> I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or
                                 double click me to add your own content and make changes to the font</p>
                        </div>  
                        <div className="features-Sec">
                            <h2 className="sec-header">Airtight Security</h2>
                            <p className="p-sec">I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or
                                 double click me to add your own content and make changes to the font</p>
                        </div>
                        


                    </div>
            </div>
          
            {/* <div className="works-app">
                <h1 className='div-header'>How the App Works</h1>
                <div className="p1">
                   
                <div className="illust-1" id="a">
                    <div className="left"id="b">
                        <h2 className="pa1-header"> /01 Personalized Experience</h2>
                        <p className="pa1"> I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or
                            double click me to add your own content and make changes to the font.</p> 
                            </div>
                        </div>
            </div>
                <div className="p2" >
               
                     <div className="illust-2">
                     <div className="right"> 
                    <h2 className="p2-header">/02 Cloud Based Technology</h2>
                    <p className="pa2"> I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or
                     double click me to add your own content and make changes to the font.</p>
                    
                     </div>
                     </div>
                     </div>
                     
                     </div> */}
                     <Services/>
                     <Footer />

        </div>
        
    )
}

export default Home;