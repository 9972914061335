import { useState } from 'react';

import { createAuthUserWithEmailAndPassword, createUserDocumentFromAuth } from '../../utils/firebase/firebase.utils';

import FormInput from '../form-input/form-input.component';

import './sign-up.styles.scss';


import Button from '../button/button.component';

const defaultFormFields = {
    displayName: '',
    ice: '',
    numero: '',
    email: '',
    password: '',
    confirmPassword: ''
}
const SignUpForm = () => {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const {displayName, ice, numero, email, password, confirmPassword} = formFields;


    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    console.log(formFields);
    
    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormFields({...formFields,  [name]: value});

    }

    const handleSubmit = async (event) => {
        //prevent any default handling of the form to handle it ourselves
        event.preventDefault();

        if (password !== confirmPassword){
            alert("passwords do not match");
            return;
        }

        try{
            const {user} = await createAuthUserWithEmailAndPassword(email, password);

           

            await createUserDocumentFromAuth(user, {displayName})
            resetFormFields();
        }catch (err) {
            if (err.code === "auth/email-already-in-use"){
                alert("cannot create user, email already in use")
            }else{
                console.log(err);
            }
            

        }
    }

    return(
        <div className='sign-up-container'>
            <h1 >Sign up with your email and password</h1>
            <span></span>
            <form onSubmit={handleSubmit}>

                <FormInput label="Nom de l'Entreprise*" type="text" required onChange={handleChange} name="displayName" value={displayName} />


                <FormInput label="ICE*" type="text" required onChange={handleChange} name="displayName" value={ice} />


                <FormInput label="Numéro*" type="text" required onChange={handleChange} name="displayName" value={numero} />


                <FormInput label="Email*" type="email" required onChange={handleChange} name="email" value={email} />


                <FormInput label="Password*" type="password" required onChange={handleChange} name="password" value={password} />


                <FormInput label="Confirm Password*" type="password" required onChange={handleChange} name="confirmPassword" value={confirmPassword} />
                <Button type="submit" >Sign Up</Button>

            </form>
        </div>
    )
}

export default SignUpForm;