import { initializeApp } from 'firebase/app';
import { 
  createUserWithEmailAndPassword,
  getAuth, 
  GoogleAuthProvider, 
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  getDoc,
  setDoc
} from 'firebase/firestore'



const firebaseConfig = {
    apiKey: "AIzaSyD8ObQKUglHlv0Z7gj_NRg8iO1PwNTky-g",
    authDomain: "ravo-25bb1.firebaseapp.com",
    projectId: "ravo-25bb1",
    storageBucket: "ravo-25bb1.appspot.com",
    messagingSenderId: "268050558938",
    appId: "1:268050558938:web:5dd2c048b146ecbd05dae3"
  };

  initializeApp(firebaseConfig);

  const provider = new GoogleAuthProvider();

  provider.setCustomParameters({
    prompt: "select_account"
  });

  export const auth = getAuth();
  export const signInWithGooglePopup = () => signInWithPopup(auth, provider);

  export const db = getFirestore();

  export const createUserDocumentFromAuth = async (userAuth, additionalInformation = {displayName: 'mike'}) => {
    const userDocRef = doc(db, 'users', userAuth.uid);
    console.log(userDocRef);
    const userSnapshot = await getDoc(userDocRef);
    console.log(userSnapshot);
    console.log(userSnapshot.exists());

    if(!userSnapshot.exists()){
      const {displayName, email} = userAuth;
      const createdAt = new Date();
      try {
        await setDoc(userDocRef, {
          displayName,
          email,
          createdAt, 
          ...additionalInformation
        });
      } catch(error){
        console.log('error creating the user', error.message)
      }
    }

    return userDocRef;
  }


  export const createAuthUserWithEmailAndPassword = async (email, password) => {
    if(!email || !password) return;
    return await createUserWithEmailAndPassword(auth, email, password);
  }

  export const signInAuthUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;
  
    return await signInWithEmailAndPassword(auth, email, password);
  };

  export const signOutUser = async () => await signOut(auth); 

  export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback);