import './services.styles.scss'
import checkmail from '../../assets/img/checkmail.png';
import quiz from '../../assets/img/quiz.png';
import reducecost from '../../assets/img/reducecost.png';
import uploading from '../../assets/img/uploading.png';


const data = {"Features": [
  {
    "icon": "checkmail",
    "title": "Lorem ipsum",
    "text": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
  },
  {
    "icon": "checkmail",
    "title": "Lorem ipsum",
    "text": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
  },
  {
    "icon": "checkmail",
    "title": "Lorem ipsum",
    "text": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
  },
  {
    "icon": "checkmail",
    "title": "Lorem ipsum",
    "text": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
  }
]}




const Services = (props) => {

  console.log(data.Features);
  return (
    <div id="features" className="text-center">
      <div className="featurescontainer">
        <div className="section-title">
          <h2>Our Services</h2>
        </div>
        <div className="features-container">
          
                <div  className="feature">
                  <img src={checkmail} alt="mapicon" className='features-icon'/>
                  <h3 style={{fontSize: "25px"}}> Publication des Expéditions </h3>
                  <p  style={{fontSize: "20px"}}>Les transporteurs publient des expéditions disponibles en fonction de leurs itinéraires de retour, fournissant des informations clés sur l'expédition et leurs services.</p>
                </div>

                <div  className="feature">
                  <img src={quiz} alt="mapicon" className='features-icon'/>
                  <h3  style={{fontSize: "25px"}}> Sélection des Expéditions</h3>
                  <p  style={{fontSize: "20px"}}>Les expéditeurs sélectionnent leur camion préféré parmi les options proposées, lançant ainsi le processus d'expédition.</p>
                </div>

                <div  className="feature">
                  <img src={reducecost} alt="mapicon" className='features-icon'/>
                  <h3  style={{fontSize: "25px"}}> Tarification Transparente</h3>
                  <p  style={{fontSize: "20px"}}>Les transporteurs génèrent rapidement des tarifs d'expédition transparents, assurant ainsi la clarté dans le processus de tarification.
                  </p>
                </div>

                <div  className="feature">
                  <img src={uploading} alt="mapicon" className='features-icon'/>
                  <h3  style={{fontSize: "25px"}}> Confirmation Instantanée</h3>
                  <p  style={{fontSize: "20px"}}>Une fois qu'un expéditeur accepte une offre, Ravostrat confirme instantanément l'expédition, facilitant une collaboration rapide entre les expéditeurs et les transporteurs.</p>
                </div>
          
        </div>
      </div>
    </div>
  );
};

export default Services;