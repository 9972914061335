import {Fragment, useState, useContext} from 'react';
import {Outlet, Link} from 'react-router-dom';
import './navigation.styles.scss';
import {ReactComponent as CrwnLogo} from '../../assets/ravo-black.svg';
import { UserContext } from '../../contexts/user.context';
import { signOutUser } from '../../utils/firebase/firebase.utils';

const Navigation = () => {
    const { currentUser } = useContext(UserContext);



    const [navbar, setNavbar] = useState(true);
    const [toggleBurger, setToggleBurger] = useState(false);
    const changeBackground = () => {
        if (window.scrollY <= 0) {
            setNavbar(true);
          } else {
            setNavbar(false);
          }
          
    }
    window.addEventListener("scroll", changeBackground)

    const clickToToggleBurger = () => {
        if(toggleBurger === true){
            setToggleBurger(false)
        } else {
            setToggleBurger(true)
        }
        
    }


    return(
        <Fragment>
            <div className = {`navigation ${navbar ? '' : 'nav-background'}`}>
                <Link className= 'logo-container' to='/'>

                    <CrwnLogo className='logo'/>
                </Link>
                <div className={`nav-links-container ${toggleBurger ? 'nav-links-container-active' : ''}`} >  
                <div className= 'nav-links-container-left' >
                    <Link className='nav-link-left black black-hover' to='/'>
                        <p>Product</p>
                    </Link>
                    <Link className='nav-link-left black black-hover' to='/findtruck'>
                        <p>Find Truck</p>
                    </Link>
                    <Link className='nav-link-left black black-hover' to='/addtruck'>
                        <p>Add Truck</p>
                    </Link>
                    <Link className='nav-link-left black black-hover' to='/'>
                        <p>Support</p>
                    </Link>
                </div>
                <div className= 'nav-links-container-right' >
                   
                    {
                        currentUser ? (
                            <Link className='nav-link-right-underline white-underline' to='/' onClick={signOutUser}>Log Out</Link>
                        ) : (
                            <Link className='nav-link-right-underline white-underline' to='/sign-in'>
                                <p>Log In</p>
                            </Link>
                        )
                    }
                    <Link className='nav-link-right-button white white-hover' to='/demo'>
                        <p style={{fontSize: "15px"}}>Demo</p>
                    </Link>
                    {
                        currentUser ? (<span></span>) : (
                            <Link className='nav-link-right-button white white-hover' to='/sign-up'>
                                <p style={{fontSize: "15px"}}>Sign Up</p>
                             </Link>
                        )
                    }
                    
                </div>
                </div>

                <div className="burger" onClick={clickToToggleBurger}>
                    <div className={`line1 ${toggleBurger ? 'burger-toggle-line1' : ''}`}></div>
                    <div className={`line2 ${toggleBurger ? 'burger-toggle-line2' : ''}`}></div>
                    <div className={`line3 ${toggleBurger ? 'burger-toggle-line3' : ''}`}></div>
                </div>

            </div>
            <Outlet />
        </Fragment>
        
    )
}

export default Navigation;